<template>
  <multiselect
    class="app_select"
    v-model="val"
    placeholder
    selectLabel
    selectedLabel
    deselectLabel
    :options="list_states"
    :searchable="false"
    :allow-empty="false"
    :disabled="disabled"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <div
        class="app-odo-t-m-icon-container"
        v-show="option.class_icon"
        :title="option.name"
      >
        <div class="app-odo-t-m-icon" :class="option.class_icon">&nbsp;</div>
      </div>
      <span v-show="showLabels" class="text-muted"
        >&nbsp;{{ option.name }}</span
      >
    </template>
    <template slot="option" slot-scope="{ option }">
      <div
        class="app-odo-t-m-icon-container"
        v-show="option.class_icon"
        :title="option.name"
      >
        <div class="app-odo-t-m-icon" :class="option.class_icon">&nbsp;</div>
      </div>
      <span v-show="showLabels" class="text-muted"
        >&nbsp;{{ option.name }}</span
      >
    </template>
  </multiselect>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      default: false
    },
    showLabels: {
      default: false
    }
  },
  data: () => ({
    // val: {}
  }),
  watch: {
    // val(to) {
    //   this.$emit("input", to.k);
    // },
    value(to) {
      // console.log('aqui pepi')
      if (this.val.k != to) {
        this.list_states.map((x) => {
          if (x.k == to) {
            this.val = x;
          }
        });
      }
    }
  },
  computed: {
    val: {
      get() {
        return this.o_states[this.value];
      },
      set(to) {
        this.$emit("input", to.k);
      }
    },
    // valueChild: {
    //   get() {
    //     return this.value;
    //   },
    //   set(val) {
    //     this.$emit("input", val);
    //   }
    // },
    o_states() {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_status_list
        : {};
    },
    list_states() {
      let oList = this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_status_list
        : {};

      // console.log(oList);
      let aList = [];
      // console.log(oList);
      for (let i in oList) {
        // console.log(oList[''])
        aList.push({
          ...oList[i],
          k: i
        });
      }
      return aList;
    }
  },
  methods: {
    reset() {
      this.val = {};
    }
  }
};
</script>

<style scoped>
@import "../tooth-icons.scss";

.app_select {
  min-width: 90px;
}
</style>
