<template>
  <div class="row">
    <div class="col">
      <h4>CONFIGURAR MENU DE ODONTOGRAMA</h4>
      <div class="form-group form-inline">
        <select class="custom-select" v-model="group">
          <option v-for="(l, index) in Groups" :key="index" :value="index">{{
            l
          }}</option>
        </select>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>VALOR</th>
            <th>TIPO</th>
            <th>ESTADO</th>
            <th>EVOLUCION</th>
            <th>COLOR</th>
            <th>ORDEN</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td>{{ l.name }}</td>
            <td>{{ l.type_id == 1 ? "Item" : "Menu" }}</td>
            <td>
              <SelectState
                :showLabels="true"
                :disabled="true"
                v-model="l.status"
              ></SelectState>
            </td>
            <td>
              <SelectState
                :showLabels="true"
                :disabled="true"
                v-model="l.status_target"
              ></SelectState>
            </td>
            <td>
              <SelectFlagColor
                v-model="l.flag"
                :disabled="true"
              ></SelectFlagColor>
            </td>
            <td>{{ l.order }}</td>
            <td>
              <button
                class="btn btn-sm btn-primary"
                @click="
                  $refs.elModal.show();
                  $refs.elForm.setValue(l);
                "
              >
                <i class="fas fa-edit"></i>
              </button>
              <button class="btn btn-danger btn-sm" @click="preDelete(l.id)">
                <i class="fas fa-trash"></i>
              </button>
              <router-link
                :to="'/dental-clinic/config/odontogram-menu?menu_id=' + l.id"
                v-show="l.type_id == 2"
                class="btn btn-secondary btn-sm"
              >
                <i class="fas fa-link"></i> items
              </router-link>
            </td>
          </tr>
          <!-- <FormMenu -->
          <!--   v-for="(l, index) in list" -->
          <!--   :key="index" -->
          <!--   :reg="l" -->
          <!--   :group="group" -->
          <!--   :menu_id="menu_id" -->
          <!--   @deleted="list.splice(index, 1)" -->
          <!-- ></FormMenu> -->
        </tbody>
      </table>

      <div class="mt-2">
        <button
          class="btn btn-primary mr-1"
          @click="
            $refs.elModal.show();
            $refs.elForm.reset();
          "
        >
          Agregar
        </button>
      </div>
    </div>
    <app-modal-basic ref="elModal">
      <Form
        ref="elForm"
        :menu_id="menu_id"
        :group="group"
        @submitted="
          getList();
          $refs.elModal.hide();
        "
      ></Form>
    </app-modal-basic>
  </div>
</template>

<script>
// import FormMenu from "./FormMenu.vue";
import Groups from "../groups.js";
import { DentalClinicService } from "../../service";
import SelectState from "./SelectState.vue";
import SelectFlagColor from "../SelectFlagColor.vue";
import Form from "./Form.vue";

export default {
  components: {
    // FormMenu
    Form,
    SelectState,
    SelectFlagColor
  },
  data: () => ({
    list: [],
    Groups,
    reg: {},
    group: 1,
    menu_id: undefined
  }),
  watch: {
    $route() {
      this.list = [];
      this.setMenuFromRoute();
      this.getList();
    },
    group() {
      if (!this.menu_id) {
        this.getList();
      }
    }
  },
  created() {
    this.setMenuFromRoute();
    this.getList();
  },
  methods: {
    preDelete(id) {
      if (window.confirm("Seguro de eliminar?")) {
        DentalClinicService.deleteOdontogramMenu(id).then(() => {
          this.getList();
        });
      }
    },
    setMenuFromRoute() {
      if (this.$route.query.menu_id) {
        this.menu_id = this.$route.query.menu_id;
      } else {
        this.menu_id = this.$route.query.menu_id;
      }
    },
    getList() {
      DentalClinicService.getOdontogramMenu({
        menu_id: this.menu_id,
        group: this.menu_id ? undefined : this.group
      }).then((res) => {
        this.list = res;
      });
    }
  }
};
</script>

<style></style>
