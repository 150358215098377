<template>
  <form @submit.prevent="save">
    <div class="mb-3">
      <label class="d-block" for="">Tipo</label>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          v-model="type_id"
          value="1"
          type="radio"
          :id="_uid + 'cr'"
          :name="'customRadio' + _uid"
          class="custom-control-input"
        />
        <label class="custom-control-label" :for="_uid + 'cr'">Opcion</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          v-model="type_id"
          value="2"
          type="radio"
          :id="_uid + 'cr2'"
          :name="'customRadio' + _uid"
          class="custom-control-input"
        />
        <label class="custom-control-label" :for="_uid + 'cr2'">Sub menu</label>
      </div>
    </div>
    <div class="mb-3">
      <label for="">Etiqueta</label>
      <input type="text" class="form-control" maxlength="255" v-model="name" />
    </div>
    <div class="mb-3">
      <label for="">Producto</label>
      <SelectProduct ref="elSelectProduct" v-model="product_id"></SelectProduct>
    </div>
    <div class="mb-3">
      <label for="">Estado inicial</label>
      <SelectState :showLabels="true" v-model="status"></SelectState>
    </div>
    <div class="mb-3">
      <label for="">Estado siguiente</label>
      <SelectState :showLabels="true" v-model="status_target"></SelectState>
    </div>
    <div class="mb-3">
      <label for="">Color</label>
      <SelectFlagColor v-model="flag"></SelectFlagColor>
    </div>
    <div class="mb-3">
      <label for="">Pieza</label>
      <select v-model="piece" class="custom-select">
        <option :value="null">Ninguno</option>
        <option value="101">Arc Sup</option>
        <option value="102">Arc Inf</option>
        <option value="2">Multiseleccion</option>
        <option value="0">Todos</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="">Orden</label>
      <input type="text" v-model="order" class="form-control" />
    </div>
    <button class="btn btn-primary" type="submit">Guardar</button>
  </form>
</template>
<script>
import SelectState from "./SelectState.vue";
import SelectFlagColor from "../SelectFlagColor.vue";
import SelectProduct from "src/store-module/products/Select.vue";
import { DentalClinicService } from "src/dental-clinic-module/service";
export default {
  components: {
    SelectProduct,
    SelectState,
    SelectFlagColor
  },

  // directives
  // filters

  props: {
    menu_id: {},
    group: {}
  },

  data: () => ({
    id: null,
    // group: null,
    name: null,
    type_id: null,
    class_icon: null,
    status: null,
    status_target: null,
    flag: null,
    product_id: null,
    piece: null,
    order: null
    // menu_id: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.id = null;
      this.name = null;
      this.type_id = 1;
      this.class_icon = null;
      this.status = null;
      this.status_target = null;
      this.flag = null;
      this.product_id = null;
      this.piece = null;
      this.order = null;
      this.$refs.elSelectProduct.reset();
    },
    setValue(value) {
      this.id = value.id;
      this.name = value.name;
      this.type_id = value.type_id;
      this.class_icon = value.class_icon;
      this.status = value.status;
      this.status_target = value.status_target;
      this.flag = value.flag;
      this.product_id = value.product_id;
      this.piece = value.piece;
      this.order = value.order;
      this.$refs.elSelectProduct.setValueFromId(value.product_id);
    },
    async save() {
      try {
        var result = await DentalClinicService.saveOdontogramMenu({
          ...this.$data,
          menu_id: this.menu_id,
          group: this.group
        });
        this.$emit("submitted", result);
      } catch (e) {
        //
      }
    }
  }
};
</script>

<style></style>
